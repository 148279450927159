import React, { Component } from "react";
import "./homeBground.scss";
import CarouselHome from "../Carousel-slick/CarouselHome";
import { Link } from "react-router-dom";
import homeVideo from "../../assets/video/two.mp4";
import posterImg from "../../assets/image/homeBackpic.png"

class HomeBground extends Component {
  render() {
    return (
      <div className="booking">
      <div className="video-background">
      <video id="background-video" autoPlay muted loop poster={posterImg}>
        <source src={homeVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        <h1>Venture beyond the familiar with our experiences</h1>
        <p> Day adventures, night comforts.</p>
        <button className='book-1'>
              <a href="https://www.secure-booking-engine.com/accounts/v99jfOkR03dXYzHQhpItgg/properties/hLbhZCq-M8Rj7opE4olhbQ/booking-engine/web/source/4wsctBw6Oq6j-g9XuxeRzQ/">
                BOOK NOW
              </a>
            </button>
      </div>
    </div>

        {/*<div className="container-b">
          <div className="image">
            <CarouselHome />
          </div>
          <div className="detail">
            <h1>Venture beyond the familiar with our experiences</h1>
            <p>
              Immersive adventures by day, restorative
              <br />
              comforts by night at our stays
            </p>
            <button className='book-1'>
              <a href="https://www.secure-booking-engine.com/accounts/v99jfOkR03dXYzHQhpItgg/properties/hLbhZCq-M8Rj7opE4olhbQ/booking-engine/web/source/4wsctBw6Oq6j-g9XuxeRzQ/">
                BOOK NOW
              </a>
            </button>
          </div>
        </div>*/}
      </div>
    );
  }
}

export default HomeBground;
