import React, { Component } from 'react';
import "./detailCard.scss";
import R_arrow from "../../assets/image/right-arrow.png";
import User from "../../assets/image/user.svg";

import ImageViewer from "react-simple-image-viewer";
import Kingbed from "../../assets/image/kingbed.svg";
import Bed from "../../assets/image/double-bed.png";
import House from "../../assets/image/house-size.png";
import Eyeview from "../../assets/image/eye.png";
import users from "../../assets/image/people.png";
import foods from "../../assets/image/food-b.svg";

import DomeStayPopup from "./domeStayPopup";
import AdamValleyPopup from "./AdamValleyPopup";

import GoogleMap from './GoogleMap';
import Dome1 from "../../assets/image/dome_2.png";
import Dome2 from "../../assets/image/dome.png";
import Bed1 from "../../assets/image/bed-1.jpg";
import Bed2 from "../../assets/image/bed-2.jpg";
import { Link } from 'react-router-dom';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Left from "../../assets/image/l-arrow.svg";
import Right from "../../assets/image/r-arrow.svg";
import PropTypes from "prop-types"
const faceContent = [
  {
      id: 1,
      icon: require("../../assets/image/dome.png"),
      
  },
  {
      id: 2,
      icon: require("../../assets/image/JAI01660-HDR-2.jpg"),
      
  },
  {
      id: 3,
      icon: require("../../assets/image/JAI01671-HDR.jpg"),
      
  },
  {
    id: 4,
    icon: require("../../assets/image/JAI01676.jpg"),
    
  },
  {
      id: 5,
      icon: require("../../assets/image/JAI01735-HDR-2.jpg"),
      
  },
  {
    id: 5,
    icon: require("../../assets/image/JAI01775.jpg"),
    
},
];

class detailCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      isViewerOpen: false,
      isPopupOpen: false,
      isDomePopupOpen: false,   // State for popup visibility
      images: [
        Dome1,
        Dome2,
        Bed1,
        Bed2
      ]
    };
  }

  openImageViewer = (index) => {
    this.setState({
      currentImage: index,
      isViewerOpen: true
    });
  };

  closeImageViewer = () => {
    this.setState({
      currentImage: 0,
      isViewerOpen: false
    });
  };

  openPopup = () => {
    this.setState({isPopupOpen: !this.state.isPopupOpen, });
    
  };

  domeOpenPopup = () => {
    this.setState({isDomePopupOpen: !this.state.isDomePopupOpen, });
  }

  closePopup = () => {
    this.setState({ isPopupOpen: false });
  };

  domeClosePopup = () => {
    this.setState({ isDomePopupOpen: false });
  };


  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3000,
      
     arrows : true,
      
      
    };
    const {isPopupOpen,isDomePopupOpen}= this.state;
    return (
      <div className="container-I mr-top">
        <div className="container">
          <div className="list-container">
            <div className="list-flex">
              <div className="picture-1">
                <div>
                  <img
                    src={Dome1}
                    alt=""
                    className="p-one"
                    onClick={() => this.openImageViewer(0)}
                  />
                </div>
              </div>
              <div className="picture-2">
                
                  <img
                    src={Dome2}
                    alt=""
                    className="p-two"
                    onClick={() => this.openImageViewer(1)}
                  />
                
                
                  <img
                    src={Bed1}
                    alt=""
                    className="p-three"
                    onClick={() => this.openImageViewer(2)}
                  />
                
               
                  <img
                    src={Bed2}
                    alt=""
                    className="p-four"
                    onClick={() => this.openImageViewer(3)}
                  />
                
                <div className="p-map">
                  <GoogleMap />
                </div>
              </div>
            </div>
            <div className="list-flex">
              <div className="r-msg">
                <h1>Dome Stay</h1>
                <p>Kovilpatti, Kodaikanal</p>
                <h4>⭐ 4.8</h4>
                <p>Steeped in the rich heritage of Chettinad, the traditional bungalow stands as a testament to the region's architectural opulence, adorned with intricate woodwork and vibrant colors, offering a glimpse into the grandeur of bygone eras amidst its sprawling courtyards and majestic pillars.</p>
              </div>
              <div className="pay">
                <div className="count-d">
                  <div className="column-1">
                    <label htmlFor="checkIn"> Check-in</label>
                    <input type="text" className='from-control' value="12 pm" />
                  </div>
                  <div className="column-2">
                    <label htmlFor="checkIn"> Check-out</label>
                    <input type="text" className='from-control' value="11 pm" />
                  </div>
                  <div className="column-3">
                    <div className="point-1">
                      <img src={User} alt="" className='room' />
                      <p>2 x Guests</p>
                    </div>
                    <div className="point-2">
                      <img src={Kingbed} className='room' alt="" />
                      <p>1 x Room</p>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
            <div className="amenities mr-bottom">
              <div className="detail-card">
                <div className="detail-img">
                <div className="container-s">
                <div className="content-slick">
                  <div className="container-slick-home-1" >
<img className='Left-arrow' src={Left} alt="" />
                    <Slider {...settings}>

                    {faceContent.map((fc) => (
                      <div className='box-slick-2' key={ fc.id} >
                        
                            <img  className="carousel-img-1" src={fc.icon} alt="" />
                        
                        
                      </div>
                    ))}
                    </Slider>
                    <img className='Right-arrow' src={Right} alt="" />
                  </div>
                </div> 
              </div>
                </div>
                <div className="details-contents">
                  <h3>Dome</h3>
                  <div className="amenties-list">
                    <div className="a-list">
                      <img src={House} alt="" className='house-img' />
                      <p> </p>
                    </div>
                    <div className="a-list">
                      <img src={Bed} alt="" />
                      <p>Double bed</p>
                    </div>
                    <div className="a-list">
                      <img src={users} alt="" className='house-img' />
                      <p>Max 3 Guests</p>
                    </div>
                    <div className="a-list">
                      <img src={Eyeview} alt="" />
                      <p>Valley view</p>
                    </div>
                    <span>
                      <img src={foods} alt="" />
                      <p>Free Breakfast Included</p>
                    </span>
                  </div>
                  <button
                  type='button'
                  onClick={this.openPopup}
                  > View More Details
                  </button>
                </div>
              </div>
              <button className='book-btn-card'><a href="https://www.secure-booking-engine.com/accounts/v99jfOkR03dXYzHQhpItgg/properties/hLbhZCq-M8Rj7opE4olhbQ/booking-engine/web/source/4wsctBw6Oq6j-g9XuxeRzQ/">BOOK NOW</a></button>
             
            </div>
            {/*<div className="amenities mr-bottom">
              <div className="detail-card">
                <div className="detail-img">
                  <img src={Bed1} alt="" />
                </div>
                <div className="details-contents">
                  <h3>Adam Valley View</h3>
                  <div className="amenties-list">
                    <div className="a-list">
                      <img src={House} alt="" className='house-img' />
                      <p>176 Sq.ft</p>
                    </div>
                    <div className="a-list">
                      <img src={Bed} alt="" />
                      <p>Double bed</p>
                    </div>
                    <div className="a-list">
                      <img src={users} alt="" className='house-img' />
                      <p>Max 3 Guests</p>
                    </div>
                    <div className="a-list">
                      <img src={Eyeview} alt="" />
                      <p>Valley view</p>
                    </div>
                    <span>
                      <img src={foods} alt="" />
                      <p>Free Breakfast Included</p>
                    </span>
                  </div>
                  <button
                  type='button'
                  onClick={this.domeOpenPopup}
                  > View More Details
                  </button>
                </div>
              </div>
              <button className='book-btn-card'><a href="https://www.secure-booking-engine.com/accounts/v99jfOkR03dXYzHQhpItgg/properties/hLbhZCq-M8Rj7opE4olhbQ/booking-engine/web/source/4wsctBw6Oq6j-g9XuxeRzQ/">BOOK NOW</a></button>
            </div>*/}
          </div>
        </div>
        {this.state.isViewerOpen && (
          <ImageViewer
            src={this.state.images}
            currentIndex={this.state.currentImage}
            onClose={this.closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)"
            }}
            closeOnClickOutside={true}
          />
        )}

        {isPopupOpen && (
          <DomeStayPopup 
          
          close={this.closePopup}
          showing={this.state.isPopupOpen}
          />
        )}
        {isDomePopupOpen && (
          <AdamValleyPopup 
          
          close={this.domeClosePopup}
          showing={this.state.isDomePopupOpen}
          />
        )}
        
      </div>
    );
  }
}
detailCard.propTypes = {
  faceContent: PropTypes.array.isRequired
  };
export default detailCard;
