import React, { Component } from 'react';
import "./contact.scss";
import emailjs from 'emailjs-com';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phone: '',
      subject: '',
      message: ''
    };

  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_ev265kf', 'template_ix2pcsj', e.target, 'KNkHlHfIbrGvuFDBB')
      .then((result) => {
          console.log(result.text);
          alert('Email successfully sent!');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send email. Please try again.');
      });
  };
  render() {
    const { name, email, phone, subject, message } = this.state;
    return (
      <div className="container-contact mr-top">
        <div className="container">
          <div className="contact-container">
            <div className="contact-header">
              <div className="contact-top">
                <h1>We're Here</h1>
              </div>
              <div className="contact-number">
                <div className="number-1">
                  <label htmlFor="">For Booking</label>
                  <p className='num-s'>P : +91 6383092505</p>
                </div>
                <div className="number-1">
                  <label htmlFor="">For Xperiences</label>
                  <p className='num-s'>P : +91 6383092505</p>
                </div>
              </div>
            </div>
            <div className="contact-content">
              <h1>Please fill out the form</h1>
              <p>We’re thrilled to hear about your interest in Xacro Xperiences!<br />
                Please complete the form below, and we'll be sure to reach out<br />
                to you promptly.
              </p>
              <form onSubmit={this.handleSubmit} className="contact-card">
        <input
          type="text"
          name="name"
          className="contact-input"
          placeholder="Your name *"
          value={name}
          onChange={this.handleChange}
          required
        />
        <input
          type="email"
          name="email"
          className="contact-input"
          placeholder="Email *"
          value={email}
          onChange={this.handleChange}
          required
        />
        <input
          type="tel"
          name="phone"
          className="contact-input"
          placeholder="Your Phone *"
          value={phone}
          onChange={this.handleChange}
          required
        />
        <input
          type="text"
          name="subject"
          className="contact-input"
          placeholder="Subject *"
          value={subject}
          onChange={this.handleChange}
          required
        />
        <textarea
          name="message"
          className=""
          placeholder="Your message *"
          rows="3"
          value={message}
          onChange={this.handleChange}
          required
        ></textarea>
        <button className="btn-contact" type="submit">Send Enquiry</button>
      </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default Contact;
