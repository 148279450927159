import React, { Component } from 'react';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import PropTypes from "prop-types"
import "./CarousleHome.scss"
const faceContent = [
        {
            id: 1,
            icon: require("../../assets/image/homeBackpic.png"),
            
        },
        {
            id: 2,
            icon: require("../../assets/image/homeBackpic.png"),
            
        },
        {
            id: 3,
            icon: require("../../assets/image/homeBackpic.png"),
            
        },
        {
          id: 4,
          icon: require("../../assets/image/homeBackpic.png"),
          
        },
        {
            id: 5,
            icon: require("../../assets/image/homeBackpic.png"),
            
          }
      ];
      
class CarouselHome extends Component {
        render() {
          const settings = {
            dots: false,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            
           arrows : false,
            
            
          };
             
          
          return (
            
            
              <div className="container-s">
                <div className="content-slick">
                  <div className="container-slick-home" >

                    <Slider {...settings}>

                    {faceContent.map((fc) => (
                      <div className='box-slick-1' key={ fc.id} >
                        
                            <img  className="carousel-img-1" src={fc.icon} alt="" />
                        
                        
                      </div>
                    ))}
                    </Slider>
                  </div>
                </div> 
              </div>
          
           
          );
        }
      }
CarouselHome.propTypes = {
faceContent: PropTypes.array.isRequired
};

export default CarouselHome;
