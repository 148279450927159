import React from 'react'
import Cardfour from "../card-4/cardFour";

import PurpleOrchid from '../detailCard/PurpleOrchid';

export default function Inner() {
  return (
    <div>
        <PurpleOrchid/>
        
    </div>
  )
}
