import React, { Component } from 'react';
import "./listingPart.scss";



class listingPart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMore: false
        };
    }

    handleShowMore = () => {
        this.setState(prevState => ({
            showMore: !prevState.showMore
        }));
    }
    render() {
        const { showMore } = this.state;
        return (
            <div>
            <div className="booking">
            <div className="container-l">
                <div className="container">
                    <div className="title">
                        <div className="part1">
                            <h1>Best Pick For You</h1>
                            <p>Discover the ultimate blend of comfort, luxury and adventure.</p>
                        </div>
                        {/*<div className="part2">
                            <button className='book'>View All</button>
                        </div>*/}

                    </div>
                    <div className="card-second-2">
                        <div className="m-card">
                            <div className="item1">
                                <a href="/domestay"><div className="pic-1">
                                    
                                </div></a>
                                <div className="h-detail">
                                    <h3>Dome Stay</h3>
                                    <p>Kovilpatti,Kodaikanal</p>
                                </div>
                                
                            </div>
                            <div className="item2">
                               <a href="/akandiveedu">
                               <div className="pic-1">
                               
                                </div>
                                </a> 
                                <div className="h-detail">
                                <h3>Akkandi Veedu</h3>
                                <p>Chettiyar park Rd, Kodaikanal.</p>
                                </div>
                                
                            </div>
                            <div className="item3">
                                <a href="/britishhouse">
                                <div className="pic-1">
                                    
                                </div>
                                </a>
                                <div className="h-detail">
                                    <h3>British House</h3>
                                    <p>Upper Lake Road, Kodaikanal.</p>
                                </div>
                               
                                </div>
                                
                            <div className="item4">
                                <a href="/purpleorchid">
                                <div className="pic-1">
                                    
                                </div></a>
                                <div className="h-detail">
                                    <h3>Purple Orchid</h3>
                                    <p>Attuvampatti, Kodaikanal</p>
                                </div>
                                
                            </div>
                            <div className="item5">
                                <a href="/aframecabin">
                                <div className="pic-1">
                                    
                                </div></a>
                                <div className="h-detail">
                                    <h3>A Frame Cabin</h3>
                                    <p>Villpatti, Kodaikanal</p>
                                </div>
                                
                            </div>
                            <div className="item6">
                                <a href="/cabinview">
                                <div className="pic-1">
                                    
                                </div></a>
                                <div className="h-detail">
                                    <h3>Cabin View</h3>
                                    <p>Villpatti, Kodaikanal</p>
                                </div>
                                
                            </div>
                            <div className="item7">
                                <a href="/tomorrowland">
                                <div className="pic-1">
                                    
                                </div></a>
                                <div className="h-detail">
                                    <h3>Tomorrowland</h3>
                                    <p>Villpatti, Kodaikanal</p>
                                </div>
                                
                            </div>
                           
                                
                        </div>
                         {/*<div className="m-card-2">
                            
                           <div className="item2">
                                <div className="pic-1">
                               
                                </div>
                                <div className="h-detail">
                                <h3>Akkandi Veedu</h3>
                                <p>Chettiyar park Rd, Kodaikanal.</p>
                                </div>
                                <div className="fare">
                                <button className='book-1'><a href="https://www.secure-booking-engine.com/accounts/v99jfOkR03dXYzHQhpItgg/properties/hLbhZCq-M8Rj7opE4olhbQ/booking-engine/web/source/4wsctBw6Oq6j-g9XuxeRzQ/">BOOK NOW</a></button>
                                </div>
                            </div>
                            <div className="item3">
                                {/*<div className="pic-1">
                                    
                                </div>
                                <div className="h-detail">
                                    <h3>British House</h3>
                                    <p>Attuvampatti, Kodaikanal.</p>
                                </div>
                                <div className="fare">
                                <button className='book-1'><a href="https://www.secure-booking-engine.com/accounts/v99jfOkR03dXYzHQhpItgg/properties/hLbhZCq-M8Rj7opE4olhbQ/booking-engine/web/source/4wsctBw6Oq6j-g9XuxeRzQ/">BOOK NOW</a></button>
                                </div>
        </div>
                        </div>*/}
                    </div>
                     {/* <div className="part2">
                    <button className='book' onClick={this.handleShowMore}>
                    {showMore ? 'View Less' : 'View All'}
                </button>
                        </div>
                  <hr />
                    <div className="title">
                        <div className="part1">
                            <h1>Experiences</h1>
                            <p>Discover the ultimate blend of comfort, luxury and adventure.</p>
                        </div>

                    </div>
                    <div className="card-second-2">
                        <div className="m-card">
                            <div className="item4">
                                <div className="pic-1">
                                    
                                </div>
                                <div className="h-detail">
                                    <h3>Dome Stay</h3>
                                    <p>Kovilpatti,Kodaikanal</p>
                                </div>
                                
                            </div>
                            <div className="item5">
                                <div className="pic-1">
                               
                                </div>
                                <div className="h-detail">
                                <h3>Akkandi Veedu</h3>
                                <p>Chettiyar park Rd, Kodaikanal.</p>
                                </div>
                                
                            </div>
                            <div className="item6">
                                <div className="pic-1">
                                    
                                </div>
                                <div className="h-detail">
                                    <h3>Mordern House</h3>
                                    <p>Attuvampatti, Kodaikanal.</p>
                                </div>
                                
                                </div>
                        </div>
                        <div className="m-card">
                            <div className="item4">
                                <div className="pic-1">
                                    
                                </div>
                                <div className="h-detail">
                                    <h3>Dome Stay</h3>
                                    <p>Kovilpatti,Kodaikanal</p>
                                </div>
                                
                            </div>
                            <div className="item5">
                                <div className="pic-1">
                               
                                </div>
                                <div className="h-detail">
                                <h3>Akkandi Veedu</h3>
                                <p>Chettiyar park Rd, Kodaikanal.</p>
                                </div>
                                
                            </div>
                            <div className="item6">
                                <div className="pic-1">
                                    
                                </div>
                                <div className="h-detail">
                                    <h3>Mordern House</h3>
                                    <p>Attuvampatti, Kodaikanal.</p>
                                </div>
                                
                                </div>
                        </div>
    </div>*/}

                    
                </div>
                </div>
                </div>
            </div>
        );
    }
}

export default listingPart;