import React from 'react'

import ListingPart from "../listingPart/listingPart";

export default function listing() {
  return (
    <div>
        <ListingPart/>
    </div>
  )
}
