import React, { Component } from "react";
import "./header.scss";
import Call from "../../assets/image/call.svg";
import logo from "../../assets/image/logoblack.png";
import Toggle from "../../assets/image/Toggle.svg";
import close from "../../assets/image/delete.svg";
import whatsapp from "../../assets/image/whazzup.png";
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenuOpen: false,
    };
    this.menuRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.menuRef.current && !this.menuRef.current.contains(event.target)) {
      this.setState({ isMenuOpen: false });
    }
  };

  closePopup = () => {
    this.setState({ isMenuOpen: false });
};

  toggleMenu = () => {
    this.setState({isMenuOpen: !this.state.isMenuOpen, });
  };

  render() {
    const { isMenuOpen } = this.state;
    return (
      <div>
        <header className="fixed-header">
          <div className="container">
            <div className="flex-group-1">
              <div className="content-one">
                <div className="logo-out">
                  <a href="/" to={{ path: "/" }}>
                    <img src={logo} alt="" className="logo-head" />
                  </a>
                </div>
              </div>
              <div className="content-two">
                <a href="/listing">Rooms</a>
                {/* <a href="">Services</a> */}
                <a href="/contact">Contact</a>
                <a className="a-phone" href={`tel: ${6383092505}`}>
                  {/* <img src={Call} alt="" /> */}
                  +91 6383092505</a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-app"
                  href={`https://wa.me/${6383092505}`}
                >
                  <img className="whatsapp" src={whatsapp} alt="" />
                </a>
                {/*<h3>+91 6383092505</h3>*/}
              </div>
              <button onClick={this.toggleMenu}>
                <img src={Toggle} alt="" />
              </button>
              {isMenuOpen && (
                <div className="list-modal">
                  <div className="content-list" >
                    <img src={close} alt=""
                    onClick={this.closePopup}
                    className="close-btn"/>
                  <div className="phone">
                  <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-app"
                  href={`https://wa.me/${6383092505}`}
                >
                  <img className="whatsapp" src={whatsapp} alt="" />
                </a>
                  <a href={`tel: ${6383092505}`} className="phone-btn"><img src={Call} alt="" />+91 6383092505</a>
                  
                </div>
               
                    <a href="/listing" className="mav-link">Rooms</a>
                    {/* <a href="" className="mav-link">Services</a> */}
                    <a href="/contact" className="mav-link">Contact</a>
                    
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
