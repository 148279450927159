import React from 'react'
import Cardfour from "../card-4/cardFour";

import AframeCabin from '../detailCard/AframeCabin';

export default function Inner() {
  return (
    <div>
        <AframeCabin/>
        
    </div>
  )
}
