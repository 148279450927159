import React, { Component } from 'react';
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import PropTypes from "prop-types"
import "./CarousleReview.scss"
import star from "../../assets/image/star.svg";
const faceContent = [
    {
        id:1,
        icon: require("../../assets/image/star5.png"),
        firstLine: "We had a great time at The British House! Karthick did an amazing job taking care of everything we asked for. The breakfast was like being at home and the room and bathroom were very clean. We would love to stay here again on our next visit.",
        secondLine: "Trodo P Sajy",
        thirdLine: "Happy customer",
        image: "",
    },
    {
        id:2,
        icon: require("../../assets/image/star5.png"),
        firstLine: "We had a good time staying here. Got to see the best view of the serene beauty from the dome stay. Coordinator Ajith did a great job in assisting us throughout our stay and also helped in  arranging a surprise for my anniversary. And valli akka really did a very good job in cooking us whatever we asked for according to our needs.",
        secondLine: "karthika mohan",
        thirdLine: "Happy customer",
        image: "",
    },
    {
        id:3,
        icon: require("../../assets/image/star5.png"),
        firstLine: "Wonderful place to stay irrespective of if you are in a small or large group. The caretaker, Sasi was very helpful. The place is neat and well maintained and is placed in the perfect location. The food served was really good. They served authentic South Indian food and it was really awesome. Even on days when we changed the menu, they were willing to oblige and made sure the food was ready on time. Kudos to the entire team for providing us with a wonderful ambience during our 4 day stay there.",
        secondLine: "Ruth Bardhan Sargunam",
        thirdLine: "Happy customer",
        image: "",
    },
    {
        id:4,
        icon: require("../../assets/image/4star.png"),
        firstLine: "Property at Awesome Location.. mesmerizing view in the early morning will make your day... Newly constructed property with quite spacious room.. very neat n tidy rooms.. Breakfast n Dinner available.. Mostly Breakfast was complementary and Dinner was charged separately based on the order.. The price for the property was quite reasonable...",
        secondLine: "Tusar Ranjan",
        thirdLine: "Happy customer",
        image: "",
    },
   
  ];

class CarouselSlickReview extends Component {
    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay:true,
            autoplaySpeed: 3000,
            swipeToSlide:4,
         };

         const settingphone = {
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay:true,
            autoplaySpeed: 3000,
            swipeToSlide:4,
         };
        return (
            <div className="container-r">
            <div className="content-slick">
              <div className="container-slick-2" >

                <Slider {...settings}>

                {faceContent.map((fc) => (
                    <div className="comment-1" key={fc.id}>
                    <div className="part-first-c">
                    <img  className="symbol" src={fc.icon} alt="" />
                        <span className='head-1'>{fc.firstLine}</span>
                    </div>
                    {/*<div className="part-second-c">
                        <div className="profile-p">
                            <span className='p-image'>{fc.image}</span>
                        </div>
                        <div className="name-tag">
                            <span className='name-c'>{fc.secondLine}</span>
                            <span className="cust-c"> {fc.thirdLine}</span>
                        </div>
                    </div>*/}
                  </div>
                ))}
                </Slider>
              </div>
              <div className="container-slick-3" >

<Slider {...settingphone}>

{faceContent.map((fc) => (
    <div className="comment-1" key={fc.id}>
    <div className="part-first-c">
    <img  className="symbol" src={fc.icon} alt="" />
        <span className='head-1'>{fc.firstLine}</span>
    </div>
    {/*<div className="part-second-c">
        <div className="profile-p">
            <span className='p-image'>{fc.image}</span>
        </div>
        <div className="name-tag">
            <span className='name-c'>{fc.secondLine}</span>
            <span className="cust-c"> {fc.thirdLine}</span>
        </div>
    </div>*/}
  </div>
))}
</Slider>
</div>
            </div> 
          </div>
        );
    }
}
CarouselSlickReview.propTypes = {
    faceContent: PropTypes.array.isRequired
    };

export default CarouselSlickReview;