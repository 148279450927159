import React from 'react'
import ReactDOM from 'react-dom';
import Cardfour from "../card-4/cardFour";
import SearchEngine from "../search-engine/searchEngine";
import Contact from "../Contact/Contact";

export default function Contacts() {
  return (
    <div>
        <Contact/>
        
    </div>
  )
}
//ReactDOM.render(<Contact />, document.getElementById('root'));