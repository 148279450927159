import React from 'react'
import Cardfour from "../card-4/cardFour";

import CabinView from '../detailCard/CabinView';

export default function Inner() {
  return (
    <div>
        <CabinView/>
        
    </div>
  )
}
