import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cancel from "../../assets/image/delete.svg";
import "./detailPopup.scss";
import Bed2 from "../../assets/image/x-stay.jpg";

class AkandiPopup extends Component {
  render() {
    const { showing, close } = this.props;
    return (
      <div className="modal" style={{ display: showing ? "block" : "none" }}>
        <form>
          <div className="modal-dialog">
            <div className="modal-content room-pad">
              <div className="container-room-pop">
                <div className="room-stay">
                  <h6>Akandi Veedu View </h6>
                  <img onClick={close} src={Cancel} alt="Close" className="close-icon"/>
                </div>
                <div className="room-image">
                <img src={Bed2} alt="bed" />
                </div>
                <h4>Amenities</h4>

                <div className="room-amenities">
                    <div className="basic">
                    <h4>Basic Facilites</h4>
                    <div className="basic-list">
                    <ul>
                     
                    <li>Balcony</li>
                    <li>Heater</li>
                    <li>Housekeeping</li>
                    <li>WiFi</li>
                    <li>Iorn & Iorning board</li>
                    <li>Bathroom</li>
                    <li>Inroom dinning</li>
                    <li>Seating Area</li>
                    </ul>
                    <ul>
                    <li>Work desk</li>
                    <li>Closet </li>
                    <li>Mirror</li>
                    <li>Chair</li>
                    <li>Clothes rack</li>
                    <li>Center lamp</li>
                    <li>Reading lamp</li>
                    <li>Wooden floors</li>
                    </ul>
                    <ul>
                    
                    
                    <li>Sofa</li>
                    <li>Charging points</li>
                    <li>Flooring</li>
                    <li>Woollen blanket</li>
                    <li>Pillows</li>
                    <li>TV</li>
                    <li>Adapted bath</li>
                    </ul>
                    </div>
                    

                    </div>
                    <div className="basic">
                    <h4>Paid Transfer</h4>
                    <div className="basic-list">
                    <ul>
                    <li>Paid Shuttle Service</li>
                    <li>Railway Station Transfer</li>
                    
                    </ul>
                    <ul>
                    <li>Paid Airport Transfer</li>
                    <li>Pickup/Drop</li>
                    
                    </ul>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

AkandiPopup.propTypes = {
  showing: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

export default AkandiPopup;
