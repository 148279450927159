import React from 'react'
import Cardfour from "../card-4/cardFour";
import SearchEngine from "../search-engine/searchEngine";
import DetailCard from "../detailCard/detailCard";
import Tomorrowland from '../detailCard/Tomorrowland';

export default function Inner() {
  return (
    <div className="">
        <Tomorrowland/>
       
    </div>
  )
}
