import React from 'react'
import Cardfour from "../card-4/cardFour";
import SearchEngine from "../search-engine/searchEngine";
import BritishHouse from "../detailCard/BritishHouse";

export default function Inner() {
  return (
    <div>
        <BritishHouse/>
        
    </div>
  )
}
