import React, { Component } from 'react';
import "./cardThree.scss";
import Quality from "../../assets/image/hotel.png"
import care from "../../assets/image/customer-care.png"
import car from "../../assets/image/jeep.png"
class cardThree extends Component {
    render() {
        return (
            <div>
                <div className="booking">
                    <div className="container-c">
                        <div className="container">
                            <div className="contact">
                                <div className="partone">
                                <div className="top-section">
                                <h1>We care for each <br /> guest like a family</h1>
                                    <p>"Welcome to Xacro, where every guest is embraced like family, and hospitality is our heartfelt commitment. Step into our warm embrace, where genuine care and personalized attention await to ensure your stay is nothing short of extraordinary."  </p>
                                </div>
                                    
                                    <div className="bottom-section">
                                    <div className="one">
                                        <div className="symbol"><img src={Quality} alt="" /></div>
                                        <div className="detail-one">
                                            <h2>Best quality Hotels</h2>
                                            <p>Experience the epitome of luxury and comfort at our resort, where we prioritize delivering the highest quality accommodations and services to exceed your expectations.</p>
                                        </div>
                                    </div>
                                    <div className="two">
                                        <div className="symbol"> <img src={care} alt="" /></div>
                                        <div className="detail-one">
                                            <h2>Responsive customer support</h2>
                                            <p>Our resort takes pride in offering round-the-clock, responsive customer support, ensuring your needs are met promptly and efficiently.</p>
                                        </div>
                                    </div>
                                    <div className="three">
                                        <div className="symbol"> <img src={car} alt="" /></div>
                                        <div className="detail-one">
                                            <h2>Local attractions and recommendations</h2>
                                            <p>At Xacro, we redefine luxury by crafting exclusive and bespoke experiences tailored to each guest's desires. From personalized service to unparalleled amenities, we ensure every moment is uniquely memorable, setting us apart as the ultimate destination for discerning travelers seeking the extraordinary</p>
                                        </div>
                                </div>
                                    </div>
                                </div>
                                <div className="parttwo">
                                    <div className="pic"></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default cardThree;