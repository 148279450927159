import React from 'react'
import Cardfour from "../card-4/cardFour";
import SearchEngine from "../search-engine/searchEngine";
import AkandiVeedu from "../detailCard/akandi";

export default function Inner() {
  return (
    <div>
        <AkandiVeedu/>
        
    </div>
  )
}
